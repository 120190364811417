import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { push } from "gatsby-link"
import cx from 'classnames';
import styles from './tabs.module.scss';
import { HTMLContent } from "../Content";

const SecurityTabs = ({ activeHash = '', tabs = [] }) => {
  if (!tabs || !tabs.length) return <span>:(</span>;

  const active = tabs.find(({ frontmatter }) => activeHash.includes(frontmatter.hash)) || tabs[0] || {};
  const setActive = (key) => (e) => {
    e.preventDefault();
    push(`/guide-security#${key}`);
  }
  return (
    <div className={cx(styles.securityTabs)}>
      <div className={cx(styles.navWrap)}>
        <nav>
          {_.map(tabs, ({ frontmatter: { hash, title }}) => {
            const onClick = setActive(hash);
            return (
              <a
                onClick={onClick}
                onMouseDown={onClick}
                key={hash}
                className={cx({[styles.active]: active.frontmatter.hash.includes(hash)})}
              >
                {title}
              </a>
          )})}
        </nav>
      </div>
      <div className={cx('container', styles.content)}>
        <HTMLContent content={active.html} />
      </div>
    </div>
  );
}

SecurityTabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({
    frontmatter: PropTypes.shape({
      hash: PropTypes.string,
      title: PropTypes.string,
    }),
    html: PropTypes.string
  })).isRequired,
  activeHash: PropTypes.string,
};

SecurityTabs.defaultProps = {
  activeHash: null
};

export default SecurityTabs;
